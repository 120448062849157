<template>
  <v-app>
    <v-main class="login_background">
      <v-row justify="center" align="center" style="height:95vh">
        <v-col cols="12" xs="12" sm="7" md="5" lg="4" class="lighten-2 fill-height d-flex flex-column justify-center align-center">
          <v-card flat color="white" min-width="95%" class="pt-10 mt-10">
            <div class="d-flex justify-center mb-3">
              <v-img src="@/assets/Cabbio.png" class="logo" max-height="146" max-width="200">  </v-img>
            </div>
            <v-card-text class="pb-8 mt-6 px-8 d-flex flex-column">
              <span class="text-h4">Agradecemos sua avaliação!</span>
              <span class="text-h6 mt-3">Agora é aguardar alguns minutos e você irá receber seu certificado por e-mail!</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'GhostRatingConfirmation'
}
</script>

<style scoped>

.login_background {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  background: url('../../assets/background_login.png');
}

</style>